.rrssb-buttons {
  box-sizing: border-box;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  height: 36px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.rrssb-buttons:after {
  clear: both;
}
.rrssb-buttons:after,
.rrssb-buttons:before {
  content: " ";
  display: table;
}
.rrssb-buttons li {
  box-sizing: border-box;
  float: left;
  height: 100%;
  line-height: 13px;
  list-style: none;
  margin: 0;
  padding: 0 2px;
}
.rrssb-buttons li.rrssb-email a {
  background-color: #0a88ff;
}
.rrssb-buttons li.rrssb-email a:hover {
  background-color: #006ed6;
}
.rrssb-buttons li.rrssb-facebook a {
  background-color: #306199;
}
.rrssb-buttons li.rrssb-facebook a:hover {
  background-color: #244872;
}
.rrssb-buttons li.rrssb-tumblr a {
  background-color: #32506d;
}
.rrssb-buttons li.rrssb-tumblr a:hover {
  background-color: #22364a;
}
.rrssb-buttons li.rrssb-linkedin a {
  background-color: #007bb6;
}
.rrssb-buttons li.rrssb-linkedin a:hover {
  background-color: #005983;
}
.rrssb-buttons li.rrssb-twitter a {
  background-color: #26c4f1;
}
.rrssb-buttons li.rrssb-twitter a:hover {
  background-color: #0eaad6;
}
.rrssb-buttons li.rrssb-googleplus a {
  background-color: #e93f2e;
}
.rrssb-buttons li.rrssb-googleplus a:hover {
  background-color: #ce2616;
}
.rrssb-buttons li.rrssb-youtube a {
  background-color: #df1c31;
}
.rrssb-buttons li.rrssb-youtube a:hover {
  background-color: #b21627;
}
.rrssb-buttons li.rrssb-reddit a {
  background-color: #8bbbe3;
}
.rrssb-buttons li.rrssb-reddit a:hover {
  background-color: #62a3d9;
}
.rrssb-buttons li.rrssb-pinterest a {
  background-color: #b81621;
}
.rrssb-buttons li.rrssb-pinterest a:hover {
  background-color: #8a1119;
}
.rrssb-buttons li.rrssb-pocket a {
  background-color: #ed4054;
}
.rrssb-buttons li.rrssb-pocket a:hover {
  background-color: #e4162d;
}
.rrssb-buttons li.rrssb-github a {
  background-color: #444;
}
.rrssb-buttons li.rrssb-github a:hover {
  background-color: #2b2b2b;
}
.rrssb-buttons li.rrssb-instagram a {
  background-color: #125688;
}
.rrssb-buttons li.rrssb-instagram a:hover {
  background-color: #0c3a5b;
}
.rrssb-buttons li.rrssb-delicious a {
  background-color: #0b79e5;
}
.rrssb-buttons li.rrssb-delicious a:hover {
  background-color: #095fb4;
}
.rrssb-buttons li.rrssb-vk a {
  background-color: #4d71a9;
}
.rrssb-buttons li.rrssb-vk a:hover {
  background-color: #3d5a86;
}
.rrssb-buttons li.rrssb-hackernews a {
  background-color: #f60;
}
.rrssb-buttons li.rrssb-hackernews a:hover {
  background-color: #cc5200;
}
.rrssb-buttons li a {
  background-color: #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  display: block;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  height: 100%;
  padding: 11px 7px 12px 27px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
}
.rrssb-buttons li a .rrssb-icon {
  display: block;
  left: 10px;
  padding-top: 9px;
  position: absolute;
  top: 0;
  width: 10%;
}
.rrssb-buttons li a .rrssb-icon svg {
  height: 17px;
  width: 17px;
}
.rrssb-buttons li a .rrssb-icon svg path {
  fill: #fff;
}
.rrssb-buttons li a .rrssb-text {
  color: #fff;
}
.rrssb-buttons li a:active {
  box-shadow: inset 1px 3px 15px 0 rgba(22, 0, 0, 0.25);
}
.rrssb-buttons li.small a {
  padding: 0;
}
.rrssb-buttons li.small a .rrssb-icon {
  left: auto;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: auto;
  width: 100%;
}
.rrssb-buttons li.small a .rrssb-text {
  visibility: hidden;
}
.rrssb-buttons.large-format,
.rrssb-buttons.large-format li {
  height: auto;
}
.rrssb-buttons.large-format li a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 0.2em;
  padding: 8.5% 0 8.5% 12%;
}
.rrssb-buttons.large-format li a .rrssb-icon {
  height: 100%;
  left: 7%;
  padding-top: 0;
  width: 12%;
}
.rrssb-buttons.large-format li a .rrssb-icon svg {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.rrssb-buttons.large-format li a .rrssb-text {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.rrssb-buttons.small-format {
  padding-top: 5px;
}
.rrssb-buttons.small-format li {
  height: 80%;
  padding: 0 1px;
}
.rrssb-buttons.small-format li a .rrssb-icon {
  height: 100%;
  padding-top: 0;
}
.rrssb-buttons.small-format li a .rrssb-icon svg {
  height: 48%;
  position: relative;
  top: 6px;
  width: 80%;
}
.rrssb-buttons.tiny-format {
  height: 22px;
  position: relative;
}
.rrssb-buttons.tiny-format li {
  padding-right: 7px;
}
.rrssb-buttons.tiny-format li a {
  background-color: transparent;
  padding: 0;
}
.rrssb-buttons.tiny-format li a .rrssb-icon svg {
  height: 70%;
  width: 100%;
}
.rrssb-buttons.tiny-format li a:active,
.rrssb-buttons.tiny-format li a:hover {
  background-color: transparent;
}
.rrssb-buttons.tiny-format li.rrssb-email a .rrssb-icon svg path {
  fill: #0a88ff;
}
.rrssb-buttons.tiny-format
  li.rrssb-email
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #0054a3;
}
.rrssb-buttons.tiny-format li.rrssb-facebook a .rrssb-icon svg path {
  fill: #306199;
}
.rrssb-buttons.tiny-format
  li.rrssb-facebook
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #18304b;
}
.rrssb-buttons.tiny-format li.rrssb-tumblr a .rrssb-icon svg path {
  fill: #32506d;
}
.rrssb-buttons.tiny-format
  li.rrssb-tumblr
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #121d27;
}
.rrssb-buttons.tiny-format li.rrssb-linkedin a .rrssb-icon svg path {
  fill: #007bb6;
}
.rrssb-buttons.tiny-format
  li.rrssb-linkedin
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #003650;
}
.rrssb-buttons.tiny-format li.rrssb-twitter a .rrssb-icon svg path {
  fill: #26c4f1;
}
.rrssb-buttons.tiny-format
  li.rrssb-twitter
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #0b84a6;
}
.rrssb-buttons.tiny-format li.rrssb-googleplus a .rrssb-icon svg path {
  fill: #e93f2e;
}
.rrssb-buttons.tiny-format
  li.rrssb-googleplus
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #a01e11;
}
.rrssb-buttons.tiny-format li.rrssb-youtube a .rrssb-icon svg path {
  fill: #df1c31;
}
.rrssb-buttons.tiny-format
  li.rrssb-youtube
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #84111d;
}
.rrssb-buttons.tiny-format li.rrssb-reddit a .rrssb-icon svg path {
  fill: #8bbbe3;
}
.rrssb-buttons.tiny-format
  li.rrssb-reddit
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #398bcf;
}
.rrssb-buttons.tiny-format li.rrssb-pinterest a .rrssb-icon svg path {
  fill: #b81621;
}
.rrssb-buttons.tiny-format
  li.rrssb-pinterest
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #5d0b11;
}
.rrssb-buttons.tiny-format li.rrssb-pocket a .rrssb-icon svg path {
  fill: #ed4054;
}
.rrssb-buttons.tiny-format
  li.rrssb-pocket
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #b61124;
}
.rrssb-buttons.tiny-format li.rrssb-github a .rrssb-icon svg path {
  fill: #444;
}
.rrssb-buttons.tiny-format
  li.rrssb-github
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #111;
}
.rrssb-buttons.tiny-format li.rrssb-instagram a .rrssb-icon svg path {
  fill: #125688;
}
.rrssb-buttons.tiny-format
  li.rrssb-instagram
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #061d2e;
}
.rrssb-buttons.tiny-format li.rrssb-delicious a .rrssb-icon svg path {
  fill: #0b79e5;
}
.rrssb-buttons.tiny-format
  li.rrssb-delicious
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #064684;
}
.rrssb-buttons.tiny-format li.rrssb-vk a .rrssb-icon svg path {
  fill: #4d71a9;
}
.rrssb-buttons.tiny-format
  li.rrssb-vk
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #2d4263;
}
.rrssb-buttons.tiny-format li.rrssb-hackernews a .rrssb-icon svg path {
  fill: #f60;
}
.rrssb-buttons.tiny-format
  li.rrssb-hackernews
  a
  .rrssb-icon:hover
  .rrssb-icon
  svg
  path {
  fill: #993d00;
}
